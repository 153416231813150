import React, { useState, useEffect } from "react";

export default function SearchBox(props) {
  const [term, setTerm] = useState();
  const [results, setResult] = useState([]);
  useEffect(() => {
    const timerId = setTimeout(() => {
      props.setKeyword(term);

      // make a request after 1 second since there's no typing
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [term]);
  return (
    <div className="core_searchbar_box">
      <span className="core_searchbar_box_icon textes">{"\ue4b8"}</span>
      <input
        type="text"
        className="textes"
        placeholder="Search icons"
        onChange={(e) => {
          setTerm(e.target.value);
        }}
      />
    </div>
  );
}
