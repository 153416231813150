import React, { useState, useEffect } from "react";
import TopLinks from "./top-links";
import Searchbar from "./searchbar/searchbar";
import Grid from "./grid/grid";
import Config from "Config";
import api from "../../api";
import "../../css/main.css";
export default function Main(props) {
  const [keyword, setKeyword] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  async function fetchData() {
    if (!isloading) {
      setLoading(true);
      api
        .getUnicodes(
          keyword,
          activeFilters ? Buffer.from(activeFilters).toString("base64") : null
        )
        .then((data) => {
          setLoading(false);
          setData(data.data);
          console.log(data.data);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }
  useEffect(() => {
    // This effect uses the `value` variable,
    // so it "depends on" `value`.
    fetchData();
  }, [activeFilters, keyword]);
  return (
    <main className="core_main">
      <div className="core_main_inner-grid">
        <TopLinks
          typefaceStyle={props.typefaceStyle}
          typeface={props.typeface}
        />
        <Searchbar
          setActiveFilters={setActiveFilters}
          setKeyword={setKeyword}
        />
        <Grid unicodes={data} />
      </div>
    </main>
  );
}
