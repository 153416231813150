import React, { useState } from "react";
import { visitFunctionBody } from "typescript";

export default function ColorSwitch(props) {
  const [isWhite, setisWhite] = useState(true);
  const [style, setstyle] = useState({
    background: "#000",
  });
  function switchStyle() {
    if (isWhite) {
      document.querySelector("body").classList.remove("white");
      document.querySelector("body").classList.add("dark");
      setstyle({ background: "#fff" });
    } else {
      document.querySelector("body").classList.add("white");
      document.querySelector("body").classList.remove("dark");
      setstyle({ background: "#000" });
    }
    setisWhite(!isWhite);
  }
  return (
    <div className="color_color_switch" onClick={switchStyle}>
      <div style={style} className="color_color_switch_bubble"></div>
      <span> {"\ue99b"}</span>
    </div>
  );
}
