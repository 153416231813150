import React from "react";

import SearchBox from "./searchbox";
import Categories from "./categories/categories";

import "../../../css/searchbar.css";

export default function Searchbar(props) {
  return (
    <React.Fragment>
      <SearchBox setKeyword={props.setKeyword} />
      <Categories setActiveFilters={props.setActiveFilters} />
    </React.Fragment>
  );
}
