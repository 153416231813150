import React from "react";

export default function Cell(props) {
  function selectText(event) {
    var element = event.target;
    var range;
    if (document.selection) {
      // IE
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      range = document.createRange();
      range.selectNode(element);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
  }
  function convertUnicode(input) {
    return input.replace(/\\u([0-9a-fA-F]{4})/g, function (a, b) {
      var charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });
  }
  return (
    <div className="core_main_icon_grid_cell">
      <div
        className="core_main_icon_grid_cell_icon"
        onClick={(event) => {
          selectText(event);
          alert(
            "Picto code " + event.target.innerText + " copied in clipboard"
          );
          document.execCommand("copy");
        }}
      >
        {convertUnicode(String(`\\u${props.unicode}`))}
      </div>
      <p className="core_main_icon_grid_cell_icon-code">{`uni${props.name.toUpperCase()}`}</p>
      <p className="core_main_icon_grid_cell_icon-tags">{props.tags}</p>
    </div>
  );
}
