import axios from "axios";
import axiosRetry from "axios-retry";
import { setupCache } from "axios-cache-adapter";
import Config from "Config";

axiosRetry(axios, { retries: 3 });
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const cachedApi = axios.create({
    baseURL: Config.url,
    headers: {
      "Content-Type": "application/json",
    },
    crossDomain: true,
    adapter: cache.adapter,
  }),
  api = axios.create({
    baseURL: Config.url,
    headers: {
      "Content-Type": "application/json",
    },
    crossDomain: true,
  });

export const getFamilies = () => api.get(`?list=1`);
export const getUnicodes = (keyword, filter) =>
  api.get(
    `?unicodes=1&${keyword ? `keyword=${keyword}&` : ``}${
      filter ? `filters=${filter}&` : ``
    }`
  );

const apis = {
  getFamilies,
  getUnicodes,
};
export default apis;
