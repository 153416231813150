import React from "react";

export default function OverlapElement(props) {
  return (
    <li
      className={`
        ${
          props.listActives
            ? props.listActives !== props.family
              ? "inactive"
              : ""
            : ""
        } textes`}
      onClick={() => {
        props.setListActives(props.family);
      }}
    >
      {props.value}
    </li>
  );
}
