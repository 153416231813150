import React, { useState } from "react";
import "../css/App.css";
import LeftBar from "../components/left-bar/left-bar";
import Main from "../components/main/main";

function App() {
  const [typefaceDisplay, settypefaceDisplay] = useState({
    name: "Regular",
    size: 5,
  });
  const [typefaceStyleDisplay, settypefaceStyleDisplay] = useState("");
  return (
    <div className="core_app">
      <LeftBar
        switchTypefaceStyle={settypefaceStyleDisplay}
        switchTypeface={settypefaceDisplay}
      />
      <Main typefaceStyle={typefaceStyleDisplay} typeface={typefaceDisplay} />
    </div>
  );
}

export default App;
