import React, { useState, useRef } from "react";

export default function MenuButton(props) {
  const Button = useRef(null);
  const [menuState, setMenuState] = useState(false);
  function toggleMenu() {
    Button.current.classList.toggle("opened");
    Button.current.setAttribute(
      "aria-expanded",
      Button.current.classList.contains("opened")
    );
  }
  return (
    <div className="menu_wrapper">
      <button
        ref={Button}
        className="menu textes"
        onClick={() => {
          toggleMenu();
          props.toggleOverlap();
        }}
        aria-label="Main Menu"
      >
        {props.overlapState ? "\ue354" : "\ue901"}
      </button>
      <span className="textes core_filter_display">{props.activeFilter}</span>
    </div>
  );
}
