import React from "react";
import OverlapElement from "./overlap-element";
export default function MenuOverlap(props) {
  return (
    <div style={props.style} className="core_searchbar_overlap">
      <div className="core_searchbar_overlap_inner">
        <ul>
          <OverlapElement
            setListActives={props.setListActives}
            listActives={props.listActives}
            key="reset"
            value="All icons"
            family="reset"
          />
          {props.list
            ? Object.keys(props.list).map((key, index) => (
                <OverlapElement
                  setListActives={props.setListActives}
                  listActives={props.listActives}
                  key={index}
                  value={props.list[key]}
                  family={key}
                />
              ))
            : ""}
        </ul>
      </div>
    </div>
  );
}
