import React from "react";

export default function Links(props) {
  return (
    <p className="core_lateralnav_link">
      <a
        className={props.active ? "core_lateralnav_link_active" : ""}
        href={props.href}
      >
        {props.name}
      </a>
    </p>
  );
}
