import React, { useState } from "react";

function Letter(props) {
  return (
    <li
      onClick={(t) => {
        props.switchTypeface({
          name: props.type,
          size: Number(props.number) + 1,
        });
        props.changeWeight(props.type);
        if (document.querySelector(".core_weight_letter_active")) {
          document
            .querySelector(".core_weight_letter_active")
            .classList.remove("core_weight_letter_active");
        }

        t.target.classList.add("core_weight_letter_active");
      }}
      className={
        "core_weight_letter " +
        (props.default === true ? "core_weight_letter_active" : "")
      }
      style={{
        fontFamily: `diglu-${props.type}`,
      }}
    >
      A
    </li>
  );
}
export default function WeightPicker(props) {
  const [currentWeight, SetWeight] = useState("regular");
  function changeWeight(v) {
    document.querySelector("body").classList.remove(`${currentWeight}`);
    document.querySelector("body").classList.add(`${v.toLowerCase()}`);
    SetWeight(v.toLowerCase());
  }
  const typos = [
    "Hairline",
    "Thin",
    "Extralight",
    "Light",
    "Regular",
    "Medium",
    "Bold",
    "Extrabold",
    "Black",
    "Heavy",
  ];

  return (
    <ul className="core_weight_list">
      {typos.map((name, index) => (
        <Letter
          key={index}
          number={index}
          type={name}
          switchTypeface={props.switchTypeface}
          default={
            name === "Regular" &&
            currentWeight &&
            !document.querySelector(".core_weight_letter_active")
          }
          changeWeight={changeWeight}
        />
      ))}
    </ul>
  );
}
