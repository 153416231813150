import React from "react";

export default function LeftBarCell(props) {
  return (
    <div
      style={props.style}
      className={"core_lateralnav_cell " + props.className}
    >
      {props.children}
    </div>
  );
}
