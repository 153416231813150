import React from "react";

import "../../../css/icon-grid.css";
import Cell from "./cell";
export default function Grid(props) {
  return (
    <div className="core_main_icon_grid">
      <div className="core_main_icon_grid_container">
        {props.unicodes
          ? props.unicodes.map((obj, index) => {
              return (
                <Cell
                  key={index + obj.name}
                  unicode={obj.unicode}
                  name={obj.name}
                  tags={obj.tags}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
}
