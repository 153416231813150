import React, { useState } from "react";

function TypoBtn(props) {
  return (
    <li
      className={props.className || ""}
      key={props.name}
      onClick={() => {
        props.setActive(props.name);
      }}
    >
      <a
        className={
          props.active === props.name ? "core_lateralnav_link_active" : ""
        }
      >
        {props.name}
      </a>
    </li>
  );
}
export default function TypoSwitch(props) {
  const [active, SetActive] = useState("regular");
  function SetTypo(n) {
    SetActive(n);
    if (n === "italic") {
      props.switchTypefaceStyle("Italic");
      document.querySelector("body").classList.add("italic");
    } else {
      if (document.querySelector("body").classList.contains("italic")) {
        props.switchTypefaceStyle("");
        document.querySelector("body").classList.remove("italic");
      }
    }
  }
  return (
    <ul className="core_typo_switch_list">
      <TypoBtn
        active={active}
        key="regular"
        name="regular"
        setActive={SetTypo}
      />
      <TypoBtn
        className="typo_switch_italic"
        active={active}
        key="italic"
        name="italic"
        setActive={SetTypo}
      />
    </ul>
  );
}
