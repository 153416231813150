import React, { useState, useEffect } from "react";
import MenuButton from "./menu-button";
import MenuOverlap from "./menu-overlap";
import "../../../../css/categories.css";
import api from "../../../../api";
export default function Categories(props) {
  const [isloading, setLoading] = useState(false);
  const [families, setFamilies] = useState([]);
  const [displayFilter, setDisplayFilter] = useState("All icons");
  async function fetchData() {
    if (!isloading) {
      setLoading(true);
      api
        .getFamilies()
        .then((data) => {
          console.log(Object.values(data.data), data.data);
          setFamilies(data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }
  useEffect(() => {
    // Update the document title using the browser API
    fetchData();
  }, []);

  const [overlapStatus, setOverlapStatus] = useState(false);
  const [style, setStyle] = useState({
    height: 0,
  });
  const [listActives, setListActives] = useState(null);

  function updateListActives(v) {
    if (v === "reset") {
      setListActives(null);
      setDisplayFilter("All icons");
    } else {
      setListActives(v);
      setDisplayFilter(families[v]);
    }
  }
  function toggleOverlap() {
    setStyle({
      minHeight: overlapStatus ? 0 : "50vh",
      height: overlapStatus ? 0 : "auto",
      opacity: overlapStatus ? 0 : 1,
      pointerEvents: overlapStatus ? "none" : "auto",
    });
    setOverlapStatus(!overlapStatus);
  }
  useEffect(() => {
    props.setActiveFilters(listActives);
  }, [listActives]);
  return (
    <div className="core_searchbar_categories">
      <MenuButton
        overlapState={overlapStatus}
        activeFilter={displayFilter}
        toggleOverlap={toggleOverlap}
      />
      <MenuOverlap
        setListActives={updateListActives}
        listActives={listActives}
        list={families}
        style={style}
      />
    </div>
  );
}
