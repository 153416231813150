import React, { useState } from "react";
import LeftBarCell from "./left-bar-cell";
import WeightPicker from "./left-bar-weight";
import ColorSwitch from "./color-switch";
import TypoSwitch from "./typo-switch";
import Links from "./links";
import "../../css/left-bar.css";
export default function LeftBar(props) {
  const lastBarElem = React.createRef();
  const [windowHeight, setwindowHeight] = useState(
    Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    )
  );
  const links = [
    {
      href: "https://diglu.ch",
      name: "Diglû",
    },
    {
      href: "https://diglu.ch/app",
      name: "Index",
      active: true,
    },
    {
      href: "https://diglu.ch/history",
      name: "History",
    },
    {
      href: "https://diglu.ch/inuse",
      name: "In Use",
    },
    {
      href: "https://shop.diglu.ch",
      name: "Buy",
    },
    {
      href: "https://diglu.ch/about",
      name: "About",
    },
    {
      href: "https://diglu.ch/license",
      name: "License",
    },
  ];
  return (
    <nav className="core_lateralnav">
      <div className="core_lateralnav-grid">
        <LeftBarCell className="core_lateralnav_logo core_lateralnav_cell-big">
          <a
            href="https://diglu.ch"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Diglû
          </a>
        </LeftBarCell>
        <LeftBarCell className="core_lateralnav_cell-big">
          <ColorSwitch />
        </LeftBarCell>
        <LeftBarCell className="core_lateralnav_cell-big core_lateralnav_cell1">
          <WeightPicker switchTypeface={props.switchTypeface} />
        </LeftBarCell>
        <LeftBarCell className="core_lateralnav_cell-small core_lateralnav_cell2">
          <TypoSwitch
            switchTypefaceStyle={props.switchTypefaceStyle}
          ></TypoSwitch>
        </LeftBarCell>
        <LeftBarCell className="core_lateralnav_cell-small core_lateralnav_cell3">
          {links.map((obj, index) => (
            <Links key={index} {...obj} />
          ))}
        </LeftBarCell>
      </div>
    </nav>
  );
}
