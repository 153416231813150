import React from "react";
import "../../css/top-links.css";
function Link(props) {
  return (
    <div
      style={props.style}
      className={"core_main_toplinks " + props.className}
    >
      {props.children}
    </div>
  );
}
export default function TopLinks(props) {
  return (
    <React.Fragment>
      <Link
        className="core_main_toplinks_copy textes"
        style={{
          gridColumnStart: 1,
          gridColumnEnd: 8,
        }}
      >
        Copy by clicking the icon
      </Link>
      <Link
        className="core_main_toplinks-right textes"
        style={{
          gridColumnStart: 8,
          gridColumnEnd: 12,
        }}
      >
        {props.typeface.name}{" "}
        {props.typefaceStyle ? `${props.typefaceStyle} ` : ""}Typeface{" "}
        {props.typeface.size}/10
      </Link>
    </React.Fragment>
  );
}
